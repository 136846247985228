/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import '../App.css'
import {  useSelector } from 'react-redux';
import { jwtDecode } from "jwt-decode";
import { Paper, Typography,Button, Avatar } from '@mui/material';
import userSlice from '../redux/slices/userSlice';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import axios from 'axios'
export default function Payment() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);

const navigate=useNavigate()
React.useEffect(()=>{
console.log(user)
    if(token==null){
        navigate('/login')
    }
},[token])
  // useEffect(()=>{
  //   const token=localStorage.getItem('accessToken')
  //   if(token!=null){
  //   const decoded = jwtDecode(token);
  //   setUser(decoded);
  //   console.log(decoded)
  //   }
  // },[])
  const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: 30,
    currency: 'KES',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email:user.email,
      //  phone_number: '0721908323',
      name: user.name,
    },
    customizations: {
      title: '30 Day Subscription',
      description: '30 day access to our service',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Define a function to update the state with the current window width
    const handleResize = () => setWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handlePaymentSuccess = async (response) => {
    // try {
    //   const verificationResponse = await axios.post('http://localhost:5000/api/payment/verify-payment', {
    //     transactionId: response.transaction_id,
    //     userEmail: email
    //   });
    //   if (verificationResponse.data.success) {
    //     alert('Payment verified successfully!');
    //   } else {
    //     alert('Payment verification failed.');
    //   }
    // } catch (error) {
    //   console.error('Error verifying payment:', error);
    //   alert('Error verifying payment.');
    // }
    closePaymentModal(); // this will close the modal programmatically
  };
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchSubscriptionStatus = () => {
      axios.get(`${process.env.REACT_APP_BACKEND}/api/payment/subscription-status/${user.email}`)
        .then(response => {
          console.log(response.data);
          setStatus(response.data.paymentStatus);
        })
        .catch(error => {
          console.error("There was an error fetching the subscription status!", error);
        });
    };

    fetchSubscriptionStatus(); // Call immediately on mount

    const intervalId = setInterval(fetchSubscriptionStatus, 5000);

    return () => clearInterval(intervalId); // Clean up on unmount or when user.email changes
  }, [user.email]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Function to add ordinal suffix to the day
    const ordinalSuffix = (day) => {
        if (day % 10 === 1 && day !== 11) {
            return day + "st";
        } else if (day % 10 === 2 && day !== 12) {
            return day + "nd";
        } else if (day % 10 === 3 && day !== 13) {
            return day + "rd";
        } else {
            return day + "th";
        }
    };
    
    // Format the date
    const formattedDate = `${ordinalSuffix(date.getDate())} ${date.toLocaleString('en-us', { month: 'short' })} ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    
    return formattedDate;
};

  return (
    <>
    <Navbar/>
      <div style={{width:"100%",display:'flex',display:'flex',alignItems:'center',justifyContent:"center"}}>
   
    <Paper style={{width:'95%',height:'100%',marginTop:100,maxWidth:800,minHeight:400,display:'flex',flexDirection:"column"}}>
   <div style={{flex:1,display:'flex'}}>
     <div style={{flex:1,backgroundColor:"white",display:'flex',flexDirection:"column",justifyItems:'center',alignItems:"center",background:'linear-gradient(45deg,#240b36,#c31432)'}}>
<Avatar  src={user.picture} style={{width:100,height:100,borderRadius:50,marginTop:20,backgroundImage:`url(${user.picture})`}}/>

       <div style={{margin:10,display:'flex',flexDirection:'column',justifyContent:"center",alignItems:'center',color:'white'


       }}>
<Typography component={'div'} variant='title'>{user.name}</Typography>
<Typography component={'div'} variant='subtitle'>{user.email}</Typography>
       </div>
     </div>
   { width>600 &&    <div style={{flex:1,backgroundColor:"#f8f8f8",backgroundImage:`url(https://cigp.com/images/38.-Mar-2021-Focus_v2.jpg)`,  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat', 
  backgroundSize: 'cover'}}></div>}
     </div>
     <div style={{flex:1,display:'flex'}}>
     <div style={{flex:1,backgroundColor:"#e5e5e5",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
     <p> {status?.isPaid ? <Typography component={'div'} variant='subtitle'>Subscription: <strong  style={{color:'#43A047'}}>Active</strong> </Typography> : <Typography component={'div'} variant='subtitle'>Subscription: <strong style={{color:'#E57373'}}>Expired</strong> </Typography>}</p>
            {status?.isPaid && <p>Expiry: {formatDate(status?.expiryDate)}</p>}
     
    {/* <Typography component={'div'} variant='title'>Last payment: Jut 16th 2024, 12:00:00 </Typography>
    <Typography component={'div'} variant='subtitle'><strong>Expiry Date:</strong> Aug 16th 2024, 12:00:00</Typography> */}
 {/* <img src="https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png" style={{width:'20%'}}/>*/}
 {/* <Typography component={'div'} variant='h6' style={{color:'green'}}> Status: Active</Typography>  */}
{width<=400 && <div style={{width:'100%',margin:10,backgroundColor:"white",display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',flexWrap:'wrap-reverse'}}>

<Typography component={'div'} variant='body1' style={{}}> Payment Methods</Typography>
<div>
    <img src='https://seeklogo.com/images/M/mpesa-logo-AE44B6F8EB-seeklogo.com.png' style={{height:30}} />
 <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6ocU97jNHKR7ZcQAPPnPTFxb7XJI_K-bEag&s' style={{height:30}} />
  
</div>

</div>}
{!status?.isPaid  && <Button color='primary' variant='contained' onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              handlePaymentSuccess()
            },
            onClose: () => {},
          });
        }} style={{margin:10}}>Payment Now</Button> }
    
     </div>
    {width>400 && <div style={{flex:1,backgroundColor:"#f8f8f8",display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',flexWrap:'wrap-reverse'}}>

     <Typography component={'div'} variant='h6' style={{margin:10}}> Payment Methods</Typography>
     <div>
         <img src='https://seeklogo.com/images/M/mpesa-logo-AE44B6F8EB-seeklogo.com.png' style={{height:50}} />
      <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6ocU97jNHKR7ZcQAPPnPTFxb7XJI_K-bEag&s' style={{height:50}} />
       
     </div>
   
     </div>}
     </div>
  </Paper>
 </div>
    </>
  
   
  );
}
// import logo from './logo.svg';
// import './App.css';
// import {Button, Paper, Typography} from '@mui/material'
// function App() {
//   return (
//     <div style={{width:"100%",display:'flex',display:'flex',alignItems:'center',justifyContent:"center"}}>
//        <Paper style={{width:'100%',height:'100%',marginTop:100,maxWidth:800,minHeight:400,display:'flex',flexDirection:"column"}}>
//       <div style={{flex:1,display:'flex'}}>
//         <div style={{flex:1,backgroundColor:"#e5e5e5",display:'flex',flexDirection:"column",justifyItems:'center',alignItems:"center"}}>

//           <Paper style={{width:100,height:100,borderRadius:50,margin:10}}></Paper>
//           <div style={{margin:10,display:'flex',flexDirection:'column',justifyContent:"center"}}>
// <Typography component={'div'} variant='subtitle'>dfd</Typography>
// <Typography component={'div'} variant='subtitle'>dfd</Typography>
//           </div>
//         </div>
//         <div style={{flex:1,backgroundColor:"#f8f8f8"}}>f</div>
//         </div>
//         <div style={{flex:1,display:'flex'}}>
//         <div style={{flex:1,backgroundColor:"#e5e5e5"}}>
//           <Button color='primary' variant='contained'>Test</Button>
//         </div>
//         <div style={{flex:1,backgroundColor:"#f8f8f8"}}></div>
//         </div>
//      </Paper>
//     </div>
//   );
// }


// {
//   "status": "successful",
//   "customer": {
//       "name": "rodger kilonzo",
//       "email": "rodgerkilonzo@gmail.com",
//       "phone_number": "254721908323"
//   },
//   "transaction_id": 1369941200,
//   "tx_ref": 1714383144873,
//   "flw_ref": "DRT9297783810814143",
//   "currency": "KES",
//   "amount": 30,
//   "charged_amount": 30,
//   "charge_response_code": "00",
//   "charge_response_message": "success",
//   "created_at": "2024-04-29T09:32:36.000Z"
// }