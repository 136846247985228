/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react/prop-types */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import React, { useEffect,useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ListItemButton from '@mui/material/ListItemButton'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ListIcon from '@mui/icons-material/List';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { BrowserRouter, Routes, Route,Navigate, useNavigate } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payments';
import axios from 'axios'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import History from '@mui/icons-material/History';
import { AccountBox } from '@mui/icons-material';
import Person from '@mui/icons-material/Person';
import GridViewIcon from '@mui/icons-material/GridView';
import ApartmentIcon from '@mui/icons-material/Apartment';
const SideNav=()=>{
const [token,setToken]=useState();
    const initiate=async ()=>{
        const userDataJson = localStorage.getItem('userData');
          
            if (userDataJson) {
        
              const userData = JSON.parse(userDataJson);
    // console.log(userData.access_token)
    if(userData==null){
      window.location.href="/mfoodadmin/login";
    }
      setToken(userData.access_token)

      try {
      
        const response = await axios.get('https://gw.sasakonnect.net/mfood/user/profile',{
            headers:{
            'Authorization':"Bearer "+userData.access_token
            }
        }

        ); 
          
    
    
             } catch (error) {
          
                  console.error(error)
                  if(error.response?.status==401){
                    window.location.href="/mfoodadmin/login";
                  }
               
           }

            }else{
                window.location.href="/mfoodadmin/login";
            }
        
        }
useEffect(()=>{
    // initiate();
},[])
const navigate=useNavigate();
const [open, setOpen] = React.useState(true);
const handleClick = () => {
    setOpen(!open);
};
    return         <List  component="nav" aria-label="mailbox folders" >
 
    <ListItem button divider 
       selected={window.location.pathname=="/" ?true:false}
       style={{fontWeight:'bold'}}
       onClick={()=>{
        navigate("/")
       }}
    >
    <ListItemIcon>
            <ApartmentIcon  />
          </ListItemIcon>
       <ListItemText primary="All Buildings" />
        
    </ListItem>    <Divider />
     <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                <CategoryIcon  />
                </ListItemIcon>
                <ListItemText primary="Groups" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>


            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}
                       selected={(window.location.pathname=="/group" || window.location.pathname=="/mfoodadmin")  ?true:false}
                       onClick={()=>{
                        navigate("/group")
                            }}
                    >
                        <ListItemIcon>
                            <GridViewIcon />
                        </ListItemIcon>
                        <ListItemText primary="Product List" />
                    </ListItemButton>
                 
                  
                </List>
            </Collapse>
  
  
  </List>
}
export default SideNav