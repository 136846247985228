
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Main from './screens/Main';
import Login from './screens/Login';
import Payment from './screens/Payment';

export default function App() {
  return (
    
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
       
       <BrowserRouter>

  <Routes>
    <Route  path="/" element={<Main/>} />
    <Route  path="/login" element={<Login/>} />
    <Route  path="/payment" element={<Payment/>} />
    <Route path='*'
         element={<div style={{textAlign:'center'}}>
  
            <div style={{paddingTop:60}}>
    <h1>Page Not Found !</h1>
         <h1>404</h1>
            </div>
            <div style={{paddingTop:60}}>
            </div>
            </div>}/>
</Routes>
   </BrowserRouter>
   </GoogleOAuthProvider>
  )
}


