/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react/prop-types */
import { Button, Paper, Typography } from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import React, { useEffect, useState } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Drawer from '@mui/material/Drawer';

import { styled, useTheme } from '@mui/material/styles';
import { Check, ErrorOutline, ErrorOutlineSharp, Warning } from "@mui/icons-material";
import MonthPayments from "./months";
import Buildings from "./components/Buildings";
import CreateBuilding from "./components/CreateBuilding";
import Floors from "./components/Floors";
import Rooms from "./components/Rooms";
const StyledDrawer = styled(Drawer)({
    minWidth: 250,
  });
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  
  const ModalContent = styled('div')({
    padding: '16px', // Example padding
  });
const Home=()=>{
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedBuilding,setSelectedBuilding]=useState(null)
    const [selectedFloor,setSelectedFloor]=useState(null)
    const [selectedRoom,setSelectedRoom]=useState(null)
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
return <div style={{width:"100%",display:"flex"}}>
    
    <div style={{width:350,minHeight:'50vh',background:"#f8f8f8",margin:5,boxShadow: "0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)"}}>
    {/* <Paper elevation={4} style={{padding:10,height:150,alignItems:"center",backgroundColor:'rgba(39,39,39,1)',display:"flex",justifyContent:"space-between",
   backgroundImage: `url("https://cdn.pixabay.com/photo/2024/01/25/12/54/building-8531835_1280.jpg")`,
  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat', 
  backgroundSize: 'cover'
    }}>
         <Typography style={{color:'white'}}>Building</Typography>
         <Button size='small' >+ Add New</Button>
        </Paper> */}
        <Paper elevation={4} style={{padding:10,alignItems:"center",backgroundColor:'rgba(39,39,39,1)',display:"flex",justifyContent:"space-between"}}>
         <Typography style={{color:'white'}}>Building</Typography>
        <CreateBuilding/>
        </Paper>
      <Buildings selected={selectedBuilding} setSelected={setSelectedBuilding} buildings={[{"floorCount":3,"name":'Sky Rise',"id":0},{"floorCount":3,"name":'Sky Rise',"id":1},{"floorCount":3,"name":'Sky Rise',"id":2}]}/>  
         </div>
    
    <div style={{flex:1,}}> 

    <div style={{minHeight:'40vh',background:"#f8f8f8",margin:5}}>
    <Paper elevation={4} style={{padding:10,alignItems:"center",backgroundColor:'rgba(39,39,39,1)',display:"flex",justifyContent:"space-between"}}>
         <Typography style={{color:'white'}}>Floors</Typography>
         <Button size='small' >+ Add New</Button>
        </Paper>
        <div style={{display:"flex",padding:5}}>
        <List style={{ display: 'flex', flexDirection: 'row' ,flexWrap:'wrap'}}>
      {/* {[1,2,3,4,5,6].map(item=><ListItem button   sx={{maxWidth:160,margin:1, backgroundColor: 'white', marginRight: 1, borderRadius: 1,boxShadow: "0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)" }}>
   
          <CalendarViewDayIcon />
          <div style={{width:10}}></div>

        <ListItemText primary={"Floor "+item} />
          <div style={{backgroundColor:'green',borderRadius:20,width:30,height:30,display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Check style={{color:'white'}}/>
  </div>
      </ListItem>)
} */}
<Floors/>
      
      </List>
            {/* <Paper elevation={1}
            style={{ padding:5,}} className='card_item'>
                <Typography variant='h6'>
Floor 1
                </Typography>
                </Paper> */}
        </div>
         </div>
         <div style={{minHeight:'40vh',background:"#f8f8f8",margin:5}}>
         <Paper elevation={4} style={{padding:10,alignItems:"center",backgroundColor:'rgba(39,39,39,1)',display:"flex",justifyContent:"space-between"}}>
         <Typography style={{color:'white'}}>Rooms</Typography>
         <Button size='small' >+ Add New</Button>
        </Paper>

        <List style={{ display: 'flex', flexDirection: 'row' ,flexWrap:'wrap'}}>
    
      <Rooms setSelected={(item)=>{setSelected(item); handleOpen();}} selected={selected} />
      </List>
         </div>

    </div>


    <StyledDrawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{position:'relative'}}
      >
           <ModalContent style={{minWidth:450,display:'flex',minHeight:'100vh',flexDirection:'column',alignItems:'center',}}>
           <DrawerHeader />
       
           <Paper elevation={4} style={{padding:10,backgroundColor:'rgba(39,39,39,1)',width:'100%'}}>
         <Typography style={{color:'white'}}>Rooms 3</Typography>
         <Typography style={{color:'white',float:'right'}}>Ksh 15,000</Typography>
        </Paper>
<MonthPayments/>
</ModalContent>
        </StyledDrawer>
    </div>
}
export default Home;