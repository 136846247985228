/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-eval */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axios from 'axios';

import { GoogleLogin,googleLogout } from '@react-oauth/google';
import { Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, clearUser } from '../redux/slices/userSlice';
import { setToken } from '../redux/slices/authSlice';

import { useNavigate } from 'react-router-dom';


export default function Login() {
  const navigate=useNavigate()
  const dispatch = useDispatch();

  const handleSubmit = async (obj) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth`, obj);
      console.log('Signup successful!', response.data);
          dispatch(setToken(response.data.token));
          dispatch(setUser(response.data.user));
     navigate('/')
      // Handle successful signup response here
    } catch (error) {
      console.error('Error signing up:', error);
      // Handle error here
    }
  };
  return (
    <> 
    {/* <div style={{width:'100vw',height:'100vh',backgroundColor:"rgba(0,0,0,0.4)",position:"absolute",top:0,left:0,backdropFilter:'blur(5px)'}}></div> */}
   
      
        <div style={{width:'100%',display:"flex",justifyContent:"center",alignItems:"center",zIndex:100}} >
          <Paper elevation={3} style={{width:'95%',borderRadius:16,overflow:'hidden',maxWidth:600,display:"flex",marginTop:100,zIndex:100}}>
       <div style={{flex:1,height:350}} className='loginImage'>
 
       </div>
       <div style={{display:'flex',minWidth:260,padding:10,position:'relative',flexDirection:'column',justifyContent:"center",alignItems:'center'}}>
       {/* <div > */}
       <div className="e-card playing" >
         <div className="image"></div>
  
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
       </div>
 
       <Typography style={{color:'white',fontWeight:'bold',fontSize:24,fontFamily:'comic sans ms'}}>Pema Rental</Typography>
       <Typography><small style={{color:'white',zIndex:1000}}>Property Management System </small></Typography>
       <br/>
          <GoogleLogin
   onSuccess={credentialResponse => {
     handleSubmit(credentialResponse);

    
   }}
   onError={() => {
     console.log('Login Failed');
   }}
   useOneTap
 />
 </div>
 
      </Paper>
    </div>

    
 </>
   )
 }
//  #FF416C #FF4B2B
// #8a2387 #e94057 #f27121

// #0f0c29 #302b63 #24243e  
// #642b73 #c6426e